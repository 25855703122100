import 'features/popup'
import 'layout/general/header'
import 'features/forms'
import Swiper from 'swiper'

/// Header dropdown
const headerDropdown = document.querySelector('button[data-header-dropdown]')

headerDropdown?.addEventListener('click', function(e: Event) {
  headerDropdown.classList.toggle('--opened')
})

/// Header search result & Menu
const searchInput = document.querySelector('input[data-header-search]')
const resultBlock = document.querySelector('.header-search__result')
const menu = document.querySelector('.menu')
const openMenuBtn = document.querySelector('button[data-action="open-mob-menu"]')

// Media
const isMobile = matchMedia('(max-width: 1100px)').matches

searchInput?.addEventListener('input', function(e: Event) {
  const target = e.target as HTMLInputElement

  if(menu?.classList.contains('--opened')) {
    menu.classList.remove('--opened')
  }

  if(target.value.length > 0) {
    resultBlock?.classList.add('--opened')
  } else {
    resultBlock?.classList.remove('--opened')
    !isMobile && menu?.classList.add('--opened')
  }
})

openMenuBtn?.addEventListener('click', function() {
  if(resultBlock?.classList.contains('--opened')){
    resultBlock.classList.remove('--opened')
  }

  menu?.classList.add('--opened')
})

searchInput?.addEventListener('click', function(e: Event) {
  const target = e.target as HTMLInputElement

  if(!isMobile && target.value.length === 0 && resultBlock?.classList.contains('--opened')){
    resultBlock.classList.remove('--opened')
  }

  if(!isMobile && target.value.length === 0) {
    menu?.classList.add('--opened')
  }
})

const closeMenuBtns = document.querySelectorAll('button[data-action="close-menu"]')
closeMenuBtns.forEach(function(closeMenu) {
  closeMenu.addEventListener('click', function() {
    menu?.classList.remove('--opened')
  })
})

/// Menu Expand submenu items
const expSubMenuBtns = document.querySelectorAll('button[data-action="exp-menu-item"]')
expSubMenuBtns.forEach(function(expBtn) {
  expBtn.addEventListener('click', function() {
    expBtn.classList.toggle('--active')
  })
})

/// Footer mobile nav
const navBtns = document.querySelectorAll('button[data-action="show-footer-menu"]')

navBtns.forEach(function(btn) {
  btn.addEventListener('click', function(e: Event) {
    //const target = e.target as HTMLButtonElement
    btn.classList.toggle('--active')
  })
})

// Search result swiper
const swiper = new Swiper('.swiper-search-result', {
  spaceBetween: 12,
  direction: 'horizontal',
  slidesPerView: 1,
});

